


































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { ILookupItem } from "@/model/LookupItem";
import { ISaveResponse } from "@/model/ISaveResponse";
import { IVerificationRecommendation } from "@/model/VerificationRecommendation";
import { Ref, Watch } from "vue-property-decorator";
import { VerifierComment, IVerifierComment } from "@/model/VerifierComment";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
    ApiButton } 
})

export default class VerifierCommentDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("answerForm") private readonly answerForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private vc: VerifierComment = new VerifierComment();
    private recommendationTypes: Array<IVerificationRecommendation> = [];

    //
    // -- methods
    //

    async edit(id: string, title: string) {
        this.reset();
        this.dialogueTitle = title;
        this.loadVerificationRecommendationTypes();
        const vcData: IVerifierComment = await apiClient.get(`api/assessment/loadVerifierComment?id=${id}`);
        this.vc.update(vcData);
        this.answerForm?.resetValidation();
        this.showDialogue = true;
    }

    private async loadVerificationRecommendationTypes() {
        this.recommendationTypes = [];
        const response = await apiClient.get("api/assessment/loadVerificationRecommendationTypes");
        this.recommendationTypes.push(...response);
    }

    private reset() {
        utils.resetObject(this.vc);
        this.recommendationTypes = [];
        this.answerForm?.resetValidation();
    }

    private async save() {
        if (!this.answerForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/assessment/saveVerifierCommentResponse", this.vc);
        toastr.success("Saved");
        this.$emit("reload");
        this.showDialogue = false;
    }
}
