










































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import store from "@/store/store";
import VerifierCommentDialogue from "./AssessmentComponents/VerifierCommentDialogue.vue";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { Prop } from "vue-property-decorator";
import { UserRole } from "@/model/Enums";
import { VerifierCommentVM } from "@/model/Api/VM/VerifierCommentVM";

@Component({
    components: { 
        ApiButton,
        VerifierCommentDialogue
    }
})
export default class VerifierCommentsPage extends Vue {

    //
    // -- properties
    //

    @Prop({}) private propAssessment !: AssessmentFormVM;
    
    //
    // -- computed properties
    //

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    private get isVerifier() {
        return store.state.signedInUser?.role == UserRole.Verifier;
    }

    //
    // -- methods
    //

    editResponse(vc: VerifierCommentVM) {
        const dialog: VerifierCommentDialogue = this.$refs.verifierCommentDialogue as VerifierCommentDialogue;
        dialog.edit(vc.verifierComment.id, vc.commentDescription);
    }

    reload() {
        this.$emit("reload");
    }

}
