var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.actionHeaders,"items":_vm.actions,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateText")(item.action.dueDate)))]}},{key:"item.action.completedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateText")(item.action.completedDate)))]}},{key:"item.action.disputed",fn:function(ref){
var item = ref.item;
return [(item.actionResponded && item.action.disputed)?_c('div',[_vm._v("Disputed")]):_vm._e(),(item.actionResponded && !item.action.disputed)?_c('div',[_vm._v("Accepted")]):_vm._e(),(!item.actionResponded)?_c('div',[_vm._v("- - -")]):_vm._e()]}},{key:"item.action.alert",fn:function(ref){
var item = ref.item;
return [(item.action.alert)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")]):_vm._e()]}},{key:"item.historyCount",fn:function(ref){
var item = ref.item;
return [(item.historyCount > 0)?_c('v-icon',{on:{"click":function($event){return _vm.viewTimeline(item.action)}}},[_vm._v("mdi-history")]):_vm._e()]}}],null,true)}),_c('verification-action-history-dialogue',{ref:"verificationActionHistoryDialogue"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }