


























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import VerificationActionHistoryDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/VerificationActionHistoryDialogue.vue";
import { IVerificationTaskActionVM } from "@/model/Api/VM/VerificationTaskActionVM";
import { Prop } from "vue-property-decorator";
import { VerificationTask } from "@/model/VerificationTask";
import { VerificationTaskAction } from "@/model/VerificationTaskAction";

@Component({
    components: {
        ApiButton,
        VerificationActionHistoryDialogue
    }
})
export default class ActionsPage extends Vue {

    async mounted() {
        this.loadActions();
    }

    //
    // -- properties
    //

    private actionHeaders = [
        { text: "Raised Against", value: "raisedAgainst", sortable: false },
        { text: "Verification Point", value: "verificationPoint", sortable: true },
        { text: "Description of Works", value: "action.description", sortable: false },
        { text: "Code Point(s)", value: "codePoints", sortable: true },
        { text: "Dispute Status", value: "action.disputed", sortable: true },
        { text: "Due Date", value: "action.dueDate", sortable: true },
        { text: "Completed Date", value: "action.completedDate", sortable: true },
        { text: "Alert", value: "action.alert", sortable: false },
        { text: "History", value: "historyCount", sortable: false }
    ];

    @Prop({}) private verificationTask !: VerificationTask;
    private actions: Array<IVerificationTaskActionVM> = [];

    //
    // -- methods
    //

    private async loadActions() {
        this.actions = [];
        const response = await apiClient.get(`/api/action/LoadAllActions?id=${this.verificationTask.id}`);
        this.actions.push(...response);
    }

    viewTimeline(action: VerificationTaskAction) {
        const dialog: VerificationActionHistoryDialogue = this.$refs.verificationActionHistoryDialogue as VerificationActionHistoryDialogue;
        dialog.open(action);
    }

}
