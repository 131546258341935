













































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import utils from "@/utilities/Utils";
import { VerificationTask } from "@/model/VerificationTask";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref, Watch } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        ConfirmDialogue
    } 
})

export default class DisputeUnresolvableDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("reasonForm") private readonly reasonForm!: VForm;

    private showDialogue: boolean = false;
    private vt: VerificationTask = new VerificationTask();

    //
    // -- methods
    //

    async open(vt: VerificationTask) {
        this.reset();
        this.vt = vt;
        this.vt.disputeUnresolvableReason = "";
        this.reasonForm?.resetValidation();
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.vt);
        this.reasonForm?.resetValidation();
    }

    confirmSave() {
        if (!this.reasonForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        
        const dialog: ConfirmDialogue = this.$refs.confirmSave as ConfirmDialogue;
        dialog.show();
    }

    private async save() {
        const response: ISaveResponse = await apiClient.post("/api/assessment/saveDisputeUnresolvableReason", this.vt);
        toastr.success("Saved");
        this.$emit("reload");
        const dialog: ConfirmDialogue = this.$refs.confirmSave as ConfirmDialogue;
        dialog.hide();
        this.showDialogue = false;
    }
}
