














































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ActionsPage from "@/vue/components/AssessmentPages/ActionsPage.vue";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import DisputeUnresolvableDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/DisputeUnresolvableDialogue.vue";
import fileDownload from "@/utilities/FileDownload";
import InfoDialogue from "@/vue/components/InfoDialogue.vue";
import QuestionnairePage from "@/vue/components/AssessmentPages/QuestionnairePage.vue";
import store from "@/store/store";
import UploadPage from "@/vue/components/AssessmentPages/UploadPage.vue";
import VerifierCommentsPage from "@/vue/components/AssessmentPages/VerifierCommentsPage.vue";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { ISaveResponse } from "@/model/ISaveResponse";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import { UserRole, AssessmentStatus, InfoType } from "@/model/Enums";
import { VerificationTask } from "@/model/VerificationTask";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ConfirmDialogue,
        DisputeUnresolvableDialogue,
        InfoDialogue,
        QuestionnairePage,
        VerifierCommentsPage,
        UploadPage,
        ActionsPage
    }
})

export default class Verification extends Vue {

    async mounted() {
        this.verificationID = this.$router.currentRoute.params.verificationID;
        this.load();
    }

    //
    // -- properties
    //

    verificationID = "";
    private readonly verificationTask = new VerificationTask();
    private readonly orgAssessment = new AssessmentFormVM();
    private readonly propAssessment = new AssessmentFormVM();
    private section = new SectionVM();
    private sectionAssessmentType = "";
    private sectionNumber = 1;

    //
    // -- computed
    //

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    private get isVerifier() {
        return store.state.signedInUser?.role == UserRole.Verifier;
    }

    private get isPrimaryLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord;
    }

    get submitEnabled() {
        var retVal = false;
        var totalQuestions = 0;
        var totalAnswers = 0;

        this.propAssessment.sections.forEach(s => {
            totalQuestions += s.questionCount;
            totalAnswers += s.answeredQuestionCount;
        })

        var totalFRARequired = 0;
        var totalFRAUploaded = 0;

        totalFRARequired = this.orgAssessment.propertyFras.length;

        this.orgAssessment.propertyFras.forEach(p => {
            if (p.friDocument.name != "") {
                totalFRAUploaded += 1;
            }
        })

        if (totalAnswers != totalQuestions) {
            retVal = true;
        }

        if (totalFRARequired != totalFRAUploaded) {
            retVal = true;
        }

        return retVal;
    }

    get completeDraftReviewEnabled() {
        var retVal = false;
        
        var totalQuestions = 0;
        var completedQuestions = 0;

        this.orgAssessment.sections.forEach(s => {
            totalQuestions += s.questionCount;
            completedQuestions += s.visitResponseCompleteQuestionCount;
        });
        this.propAssessment.sections.forEach(s => {
            totalQuestions += s.questionCount;
            completedQuestions += s.visitResponseCompleteQuestionCount;
        })

        var totalVerifierComments = 0;
        var totalRespondedVerifierComments = 0;

        totalVerifierComments = this.propAssessment.verifierComments.length;

        this.propAssessment.verifierComments.forEach(vc => {
            if (vc.verifierComment.verifierResponse.length > 0) {
                totalRespondedVerifierComments += 1;
            } else if (vc.verifierComment.verifierResponseID > 0) {
                totalRespondedVerifierComments += 1;
            }
        })

        if (totalQuestions != completedQuestions) { retVal = true; }
        if (totalVerifierComments != totalRespondedVerifierComments) { retVal = true; }

        return retVal;
    }

    get completeActionResponsesEnabled() {
        var retVal = false;
        
        var totalActions = 0;
        var completedActions = 0;

        this.orgAssessment.sections.forEach(s => {
            totalActions += s.actionCount;
            completedActions += s.respondedActionCount;
        });
        this.propAssessment.sections.forEach(s => {
            totalActions += s.actionCount;
            completedActions += s.respondedActionCount;
        })

        var totalVerifierComments = 0;
        var totalRespondedVerifierComments = 0;

        totalVerifierComments = this.propAssessment.verifierComments.length;

        if (totalActions != completedActions) { retVal = true; }

        return retVal;
    }

    get completeDisputeResponseButtonEnabled() {
        var totalDisputed = 0;

        this.orgAssessment.sections.forEach(s => {
            totalDisputed += s.disputedActionCount;
        });
        this.propAssessment.sections.forEach(s => {
            totalDisputed += s.disputedActionCount;
        })

        return totalDisputed > 0;
    }

    get isVisitStatus() {
        return this.propAssessment.assessment.status == AssessmentStatus.Visit;
    }

    get completeActionResponsesVisible() {
        return  this.propAssessment.assessment.status == AssessmentStatus.DraftReview ||
                this.propAssessment.assessment.status == AssessmentStatus.DisputedDraft
    }

    get disputeButtonsVisible() {
        return this.propAssessment.assessment.status == AssessmentStatus.Dispute;
    }

    get questionnaireBlobColour() {
        let retVal = "green";

        // schedule visit / SSAQ
        if (this.propAssessment.assessment.status == AssessmentStatus.ScheduleVisit || this.propAssessment.assessment.status == AssessmentStatus.SSAQ) {
            if (this.isVerifier) {
                retVal = "grey";
                return retVal;
            } else if (this.isLandlord) {
                var totalQuestions = 0;
                var totalAnswers = 0;

                // this.orgAssessment.sections.forEach(s => {
                //     totalQuestions += s.questionCount;
                //     totalAnswers += s.answeredQuestionCount;
                // });
                this.propAssessment.sections.forEach(s => {
                    totalQuestions += s.questionCount;
                    totalAnswers += s.answeredQuestionCount;
                });

                if (totalQuestions != totalAnswers) {
                    retVal = "red";
                } else {
                    retVal = "green";
                }
                
                return retVal;
            }
        }

        // visit
        if (this.propAssessment.assessment.status == AssessmentStatus.Visit) {
            if (this.isLandlord) {
                retVal = "grey";
                return retVal;
            } else if (this.isVerifier) {
                this.orgAssessment.sections.forEach(s => {
                    s.questions.forEach(q => {
                        if (!q.answer.visitResponseComplete) {
                            retVal = "red";
                        }
                    })
                });
                this.propAssessment.sections.forEach(s => {
                    s.questions.forEach(q => {
                        if (!q.answer.visitResponseComplete) {
                            retVal = "red";
                        }
                    })
                });

                return retVal;
            }
        }

        // draft review / disputed draft
        if (this.propAssessment.assessment.status == AssessmentStatus.DraftReview || this.propAssessment.assessment.status == AssessmentStatus.DisputedDraft) {
            if (this.isVerifier) {
                retVal = "grey";
                return retVal;
            } else if (this.isLandlord) {
                var actionCount = 0;
                var respondedActionCount = 0;

                this.orgAssessment.sections.forEach(s => {
                    actionCount += s.actionCount;
                    respondedActionCount += s.respondedActionCount;
                });
                this.propAssessment.sections.forEach(s => {
                    actionCount += s.actionCount;
                    respondedActionCount += s.respondedActionCount;
                });

                if (actionCount == 0) {
                    retVal = "grey";
                    return retVal;
                } else if (actionCount == respondedActionCount) {
                    return retVal;
                } else if (actionCount > 0 && respondedActionCount == 0) {
                    retVal = "red";
                    return retVal
                } else {
                    retVal = "#FFBF00";
                    return retVal;
                }
            }
        }

        // dispute
        if (this.propAssessment.assessment.status == AssessmentStatus.Dispute) {
            if (this.isLandlord) {
                retVal = "grey";
                return retVal;
            } else if (this.isVerifier) {
                var disputedActionCount = 0;

                this.orgAssessment.sections.forEach(s => {
                    disputedActionCount += s.disputedActionCount;
                });
                this.propAssessment.sections.forEach(s => {
                    disputedActionCount += s.disputedActionCount;
                });

                if (disputedActionCount == 0) {
                    retVal = "grey";
                } else {
                    retVal = "red";
                }

                return retVal;
            }
        }

        // remaining
        else {
            retVal = "grey";
        }
        
        return retVal;
    }

    get uploadsBlobColour() {
        let retVal = "green";

        // FRAs
        var requiredFRAs = this.orgAssessment.propertyFras.length;
        var uploadedFRAs = 0;

        this.orgAssessment.propertyFras.forEach(pd => {
            if (pd.friDocument.name != "") {
                uploadedFRAs += 1;
            }
        })

        if (requiredFRAs != uploadedFRAs) {
            retVal = "red";
        }

        // regular uploads

        this.propAssessment.sections.forEach(s => {
            s.questions.forEach(q => {
                q.answerUploads.forEach(au => {
                    if (!au.answerUpload.completed) {
                        retVal = "red";
                    }
                })
            })
        })

        return retVal;
    }

    get verifierCommentBlobColour() {
        let retVal = "green";

        this.propAssessment.verifierComments.forEach(vc => {
            if (vc.verifierComment.verifierResponse == "" && vc.verifierComment.verifierResponseID == 0) {
                retVal = "red";
            }
        })

        return retVal;
    }

    get pdfDownloadVisible() {
        var actionCount = 0;

        this.orgAssessment.sections.forEach(s => {
            actionCount += s.actionCount;
        });
        this.propAssessment.sections.forEach(s => {
            actionCount += s.actionCount;
        });

        return actionCount > 0;
    }

    get verifierCommentsTabVisible() {
        return  this.propAssessment.assessment.status == AssessmentStatus.DraftReview ||
                this.propAssessment.assessment.status == AssessmentStatus.Dispute ||
                this.propAssessment.assessment.status == AssessmentStatus.DisputedDraft ||
                this.propAssessment.assessment.status == AssessmentStatus.DisputeUnresolvable ||
                this.propAssessment.assessment.status == AssessmentStatus.Verified ||
                this.propAssessment.assessment.status == AssessmentStatus.Completed ||
                this.propAssessment.assessment.status == AssessmentStatus.Archived
    }

    get actionsTabVisible() {
        var totalActions = 0;

        this.orgAssessment.sections.forEach(s => {
            totalActions += s.actionCount;
        });
        this.propAssessment.sections.forEach(s => {
            totalActions += s.actionCount;
        })

        if (totalActions == 0) {
            return false;
        } else return true;
    }

    //
    // -- methods
    //

    private async load() {
        const retVal = await apiClient.get(`api/assessment/LoadVerification?id=${this.verificationID}`);
        this.verificationTask.update(retVal.verificationType);
        this.orgAssessment.update(retVal.organisationAssessment);
        this.propAssessment.update(retVal.propertyAssessment);
        this.resetSection();
    }

    setSection(section: SectionVM) {
        this.orgAssessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.propAssessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.orgAssessment.sections.forEach(s => {
            if (s.assessmentSection.id == section.assessmentSection.id) {
                s.isSelected = true;
                this.section = section;
                this.sectionAssessmentType = "o";
                this.sectionNumber = section.sectionNumber;
            }
        });

        this.propAssessment.sections.forEach(s => {
            if (s.assessmentSection.id == section.assessmentSection.id) {
                s.isSelected = true;
                this.section = section;
                this.sectionAssessmentType = "p";
                this.sectionNumber = section.sectionNumber;
            }
        });

        this.load();
    }

    sectionForward() {
        this.sectionNumber += 1;

        this.orgAssessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.propAssessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.orgAssessment.sections.forEach(s => {
            if (s.sectionNumber == this.sectionNumber) {
                s.isSelected = true;
                this.section = s;
                this.sectionAssessmentType = "o";
            }
        });

        this.propAssessment.sections.forEach(s => {
            if (s.sectionNumber == this.sectionNumber) {
                s.isSelected = true;
                this.section = s;
                this.sectionAssessmentType = "p";
            }
        });

        this.load();
        window.scrollTo(0, 0);
    }

    sectionBack() {
        this.sectionNumber -= 1;

        this.orgAssessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.propAssessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.orgAssessment.sections.forEach(s => {
            if (s.sectionNumber == this.sectionNumber) {
                s.isSelected = true;
                this.section = s;
                this.sectionAssessmentType = "o";
            }
        });

        this.propAssessment.sections.forEach(s => {
            if (s.sectionNumber == this.sectionNumber) {
                s.isSelected = true;
                this.section = s;
                this.sectionAssessmentType = "p";
            }
        });

        this.load();
        window.scrollTo(0, 0);
    }

    resetSection() {
        var selectedSectionCount = 0;

        this.orgAssessment.sections.forEach(s => {
            if (s.assessmentSection.id == this.section.assessmentSection.id && this.section.isSelected) {
                s.isSelected = true;
                this.section = s;
                this.sectionNumber = s.sectionNumber;
                selectedSectionCount++;
            }
        })

        this.propAssessment.sections.forEach(s => {
            if (s.assessmentSection.id == this.section.assessmentSection.id && this.section.isSelected) {
                s.isSelected = true;
                this.section = s;
                this.sectionNumber = s.sectionNumber;
                selectedSectionCount++;
            }
        })

        // set first section on initial load
        if (selectedSectionCount == 0) {
            this.orgAssessment.sections[0].isSelected = true;
            this.section = this.orgAssessment.sections[0];
            this.sectionAssessmentType = "o";
            this.sectionNumber = 1;
        }
    }

    private confirmSubmitAssessment() {
        const dialog: ConfirmDialogue = this.$refs.submitConfirmDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async downloadReport() {
        this.verificationTask.pdfReportIsLoading = true;
        this.verificationTask.pdfReportButtonText = "Downloading Report...";

        await fileDownload.download(`/api/document/downloadVerificationTaskReport?id=${this.verificationTask.id}`, "VerificationTaskReport");

        this.verificationTask.pdfReportButtonText = "Download to PDF";
        this.verificationTask.pdfReportIsLoading = false;
    }

    private async submitAssessment() {
        const response: ISaveResponse = await apiClient.post("/api/assessment/submitPropertyAssessment", this.propAssessment.assessment);
        if (response.isSuccess) {
            toastr.success("Submitted");
            this.load();
        }

        const dialog: ConfirmDialogue = this.$refs.submitConfirmDialogue as ConfirmDialogue;
        dialog.hide();

        const infoDialog: InfoDialogue = this.$refs.infoDialogue as InfoDialogue;
        infoDialog.open(InfoType.SSAQCompleted);
    }

    private confirmCompleteDraftReviewAssessment() {
        const dialog: ConfirmDialogue = this.$refs.completeDraftReviewDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async completeDraftReview() {
        const response: ISaveResponse = await apiClient.post("/api/assessment/completeDraftReview", this.verificationTask);
        if (response.isSuccess) {
            toastr.success("Visit Draft Review Completed");
            this.load();
        }
        
        const dialog: ConfirmDialogue = this.$refs.completeDraftReviewDialogue as ConfirmDialogue;
        dialog.hide();
    }

    private confirmCompleteActionResponsesAssessment() {
        const dialog: ConfirmDialogue = this.$refs.completeActionResponsesDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async completeActionResponses() {
        var disputedActionCount = 0;

        this.orgAssessment.sections.forEach(s => {
            disputedActionCount += s.disputedActionCount;
        });
        this.propAssessment.sections.forEach(s => {
            disputedActionCount += s.disputedActionCount;
        });

        if (disputedActionCount == 0) {
            const response: ISaveResponse = await apiClient.post("/api/assessment/setVerificationToVerified", this.verificationTask);
            if (response.isSuccess) {
                toastr.success("Updated Successfully");
                this.load();
            }
        } else {
            const response: ISaveResponse = await apiClient.post("/api/assessment/setVerificationToDispute", this.verificationTask);
            if (response.isSuccess) {
                toastr.success("Disputed actions sent for review");
                this.load();
            }
        }

        const dialog: ConfirmDialogue = this.$refs.completeActionResponsesDialogue as ConfirmDialogue;
        dialog.hide();
    }

    private confirmCompleteDisputeResponses() {
        const dialog: ConfirmDialogue = this.$refs.completeDisputeResponsesDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async completeDisputeResponses() {
        const response: ISaveResponse = await apiClient.post("/api/assessment/setVerificationToDisputedDraft", this.verificationTask);
            if (response.isSuccess) {
                toastr.success("Actions returned to the Landlord");
                this.load();
            }

        const dialog: ConfirmDialogue = this.$refs.completeDisputeResponsesDialogue as ConfirmDialogue;
        dialog.hide();
    }

    private confirmDisputeUnresolvable() {
        const dialog: DisputeUnresolvableDialogue = this.$refs.disputeUnresolvableDialogue as DisputeUnresolvableDialogue;
        dialog.open(this.verificationTask);
    }
}
